// VP Framework
// Grid
// Grid Definition by _hexenbrenner and Dkamerad of Vertical Path

// This file is namespaced
// o-   object
// c-   component
// u-   utility
// js-  binding for javascript interactivety
// qa-  binding for quality assurance testing

.l-grid {
  margin-left: -$column-gap;
  margin-right: -$column-gap;
  //margin-top: -$column-gap;

  &.is-centered {
    justify-content: center;
  }

  &.is-gapless {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;

    & > .l-grid__col {
      margin: 0;
      padding: 0 !important;
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.is-mobile {
    display: flex;
  }

  &.is-multiline {
    flex-wrap: wrap;
  }

  &.is-vcentered {
    align-items: center;
  }

  // Responsiveness
  @include tablet {
    &:not(.is-desktop) {
      display: flex;
    }
  }


  @include desktop {
    // Modifiers
    &.is-desktop {
      display: flex;
    }
  }
}

.l-grid__col {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: $column-gap;

  // &:last-child{
  //   margin-bottom: -$column-gap;
  // }

  // &:not(:last-child) {
  //   margin-bottom: calc(1.5rem - #{$column-gap});
  // }

  // Mobile
  @for $i from 1 through 12 {
    .l-grid.is-mobile > &.is-#{$i} {
    flex: none;
    width: percentage($i / 12);
    }

    .l-grid.is-mobile > &.is-offset-#{$i} {
    margin-left: percentage($i / 12);
    }
  }

  // Global
  @for $i from 1 through 12 {
    &.is-#{$i} {
      flex: none;
      width: percentage($i / 12);
    }

    &.is-offset-#{$i} {
      margin-left: percentage($i / 12);
    }
  }

  // Tablet
  @if $bp-tablet-enabled {
    @include tablet {
      @for $i from 1 through 12 {
        &.is-#{$i}-tablet {
          flex: none;
          width: percentage($i / 12);
        }

        &.is-offset-#{$i}-tablet {
          margin-left: percentage($i / 12);
        }
      }
    }
  }

  //Desktop
  @if $bp-desktop-enabled {
    @include desktop {
      @for $i from 1 through 12 {
        &.is-#{$i}-desktop {
          flex: none;
          width: percentage($i / 12);
        }

        &.is-offset-#{$i}-desktop {
          margin-left: percentage($i / 12);
        }
      }
    }
  }

  //Full
  @if $bp-full-enabled {
    @include full {
      @for $i from 1 through 12 {
        &.is-#{$i}-full {
          flex: none;
          width: percentage($i / 12);
        }

        &.is-offset-#{$i}-full {
          margin-left: percentage($i / 12);
        }
      }
    }
  }

  //HD
  @if $bp-hd-enabled {
    @include hd {
      @for $i from 1 through 12 {
        &.is-#{$i}-hd {
          flex: none;
          width: percentage($i / 12);
        }

        &.is-offset-#{$i}-hd {
          margin-left: percentage($i / 12);
        }
      }
    }
  }
}

@if $variable-columns {
  .l-grid.is-variable {
    --columnGap: 0.75rem;
    margin-left: calc(-1 * var(--columnGap));
    margin-right: calc(-1 * var(--columnGap));

    .l-grid__col {
      padding-left: var(--columnGap);
      padding-right: var(--columnGap);
    }

    @for $i from 0 through 8 {
      &.is-#{$i} {
        --columnGap: #{$i * 0.25rem};
      }

      @include mobile {
        &.is-#{$i}-mobile {
          --columnGap: #{$i * 0.25rem};
        }
      }


      @include tablet {
        &.is-#{$i}-tablet {
          --columnGap: #{$i * 0.25rem};
        }
      }


      @include tablet-only {
        &.is-#{$i}-tablet-only {
          --columnGap: #{$i * 0.25rem};
        }
      }

      @include desktop {
        &.is-#{$i}-desktop {
          --columnGap: #{$i * 0.25rem};
        }
      }


      @include desktop-only {
        &.is-#{$i}-desktop-only {
          --columnGap: #{$i * 0.25rem};
        }
      }


      @include full {
        &.is-#{$i}-full {
          --columnGap: #{$i * 0.25rem};
        }
      }


      @include full-only {
        &.is-#{$i}-full-only {
          --columnGap: #{$i * 0.25rem};
        }
      }


      @include hd {
        &.is-#{$i}-hd {
          --columnGap: #{$i * 0.25rem};
        }
      }
    }
  }
}