.s-style-guide {
  position: absolute;
  font-family: "Montserrat", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  font-size: 14px;
  background-color: #eaeaea;
  margin-bottom: 4rem;
  padding: .1rem .6rem;
  display: inline-block;
  text-transform: uppercase;
  border-radius: .25rem; }

.s-section {
  margin-bottom: 32px; }

*, *::after, *::before {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  padding: 0; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  text-decoration: none;
  background-color: transparent; }
  a:active, a:hover {
    outline: 0; }

b,
strong {
  font-weight: bold; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

form {
  margin: 0; }

fieldset {
  border: 0;
  margin: 0;
  padding: 0; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button,
input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  padding: 0; }

input[type="search"] {
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

.l-container {
  margin: 0 auto;
  position: relative; }
  @media screen and (max-width: 767px) {
    .l-container {
      margin-left: 1.953rem;
      margin-right: 1.953rem; } }
  @media screen and (min-width: 768px), print {
    .l-container {
      max-width: 50.95114rem;
      width: 50.95114rem; } }
  @media screen and (min-width: 1040px) {
    .l-container {
      max-width: 70.37971rem;
      width: 70.37971rem; }
      .l-container.is-fluid {
        margin-left: 1.953rem;
        margin-right: 1.953rem;
        max-width: none;
        width: auto; } }
  @media screen and (min-width: 1200px) {
    .l-container {
      max-width: 81.80829rem;
      width: 81.80829rem; } }
  @media screen and (min-width: 1400px) {
    .l-container {
      max-width: 96.094rem;
      width: 96.094rem; } }
  @media screen and (max-width: 1199px) {
    .l-container.is-full {
      max-width: 81.80829rem;
      width: auto; } }
  @media screen and (max-width: 1399px) {
    .l-container.is-hd {
      max-width: 96.094rem;
      width: auto; } }

.l-container.is-unset {
  position: unset; }

.o-section {
  margin: 0 0 3.906rem 0; }

.l-grid {
  margin-left: -1.953rem;
  margin-right: -1.953rem; }
  .l-grid.is-centered {
    justify-content: center; }
  .l-grid.is-gapless {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0; }
    .l-grid.is-gapless > .l-grid__col {
      margin: 0;
      padding: 0 !important; }
    .l-grid.is-gapless:not(:last-child) {
      margin-bottom: 1.5rem; }
    .l-grid.is-gapless:last-child {
      margin-bottom: 0; }
  .l-grid.is-mobile {
    display: flex; }
  .l-grid.is-multiline {
    flex-wrap: wrap; }
  .l-grid.is-vcentered {
    align-items: center; }
  @media screen and (min-width: 768px), print {
    .l-grid:not(.is-desktop) {
      display: flex; } }
  @media screen and (min-width: 1040px) {
    .l-grid.is-desktop {
      display: flex; } }

.l-grid__col {
  display: block;
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  padding: 1.953rem; }
  .l-grid.is-mobile > .l-grid__col.is-1 {
    flex: none;
    width: 8.33333%; }
  .l-grid.is-mobile > .l-grid__col.is-offset-1 {
    margin-left: 8.33333%; }
  .l-grid.is-mobile > .l-grid__col.is-2 {
    flex: none;
    width: 16.66667%; }
  .l-grid.is-mobile > .l-grid__col.is-offset-2 {
    margin-left: 16.66667%; }
  .l-grid.is-mobile > .l-grid__col.is-3 {
    flex: none;
    width: 25%; }
  .l-grid.is-mobile > .l-grid__col.is-offset-3 {
    margin-left: 25%; }
  .l-grid.is-mobile > .l-grid__col.is-4 {
    flex: none;
    width: 33.33333%; }
  .l-grid.is-mobile > .l-grid__col.is-offset-4 {
    margin-left: 33.33333%; }
  .l-grid.is-mobile > .l-grid__col.is-5 {
    flex: none;
    width: 41.66667%; }
  .l-grid.is-mobile > .l-grid__col.is-offset-5 {
    margin-left: 41.66667%; }
  .l-grid.is-mobile > .l-grid__col.is-6 {
    flex: none;
    width: 50%; }
  .l-grid.is-mobile > .l-grid__col.is-offset-6 {
    margin-left: 50%; }
  .l-grid.is-mobile > .l-grid__col.is-7 {
    flex: none;
    width: 58.33333%; }
  .l-grid.is-mobile > .l-grid__col.is-offset-7 {
    margin-left: 58.33333%; }
  .l-grid.is-mobile > .l-grid__col.is-8 {
    flex: none;
    width: 66.66667%; }
  .l-grid.is-mobile > .l-grid__col.is-offset-8 {
    margin-left: 66.66667%; }
  .l-grid.is-mobile > .l-grid__col.is-9 {
    flex: none;
    width: 75%; }
  .l-grid.is-mobile > .l-grid__col.is-offset-9 {
    margin-left: 75%; }
  .l-grid.is-mobile > .l-grid__col.is-10 {
    flex: none;
    width: 83.33333%; }
  .l-grid.is-mobile > .l-grid__col.is-offset-10 {
    margin-left: 83.33333%; }
  .l-grid.is-mobile > .l-grid__col.is-11 {
    flex: none;
    width: 91.66667%; }
  .l-grid.is-mobile > .l-grid__col.is-offset-11 {
    margin-left: 91.66667%; }
  .l-grid.is-mobile > .l-grid__col.is-12 {
    flex: none;
    width: 100%; }
  .l-grid.is-mobile > .l-grid__col.is-offset-12 {
    margin-left: 100%; }
  .l-grid__col.is-1 {
    flex: none;
    width: 8.33333%; }
  .l-grid__col.is-offset-1 {
    margin-left: 8.33333%; }
  .l-grid__col.is-2 {
    flex: none;
    width: 16.66667%; }
  .l-grid__col.is-offset-2 {
    margin-left: 16.66667%; }
  .l-grid__col.is-3 {
    flex: none;
    width: 25%; }
  .l-grid__col.is-offset-3 {
    margin-left: 25%; }
  .l-grid__col.is-4 {
    flex: none;
    width: 33.33333%; }
  .l-grid__col.is-offset-4 {
    margin-left: 33.33333%; }
  .l-grid__col.is-5 {
    flex: none;
    width: 41.66667%; }
  .l-grid__col.is-offset-5 {
    margin-left: 41.66667%; }
  .l-grid__col.is-6 {
    flex: none;
    width: 50%; }
  .l-grid__col.is-offset-6 {
    margin-left: 50%; }
  .l-grid__col.is-7 {
    flex: none;
    width: 58.33333%; }
  .l-grid__col.is-offset-7 {
    margin-left: 58.33333%; }
  .l-grid__col.is-8 {
    flex: none;
    width: 66.66667%; }
  .l-grid__col.is-offset-8 {
    margin-left: 66.66667%; }
  .l-grid__col.is-9 {
    flex: none;
    width: 75%; }
  .l-grid__col.is-offset-9 {
    margin-left: 75%; }
  .l-grid__col.is-10 {
    flex: none;
    width: 83.33333%; }
  .l-grid__col.is-offset-10 {
    margin-left: 83.33333%; }
  .l-grid__col.is-11 {
    flex: none;
    width: 91.66667%; }
  .l-grid__col.is-offset-11 {
    margin-left: 91.66667%; }
  .l-grid__col.is-12 {
    flex: none;
    width: 100%; }
  .l-grid__col.is-offset-12 {
    margin-left: 100%; }
  @media screen and (min-width: 768px), print {
    .l-grid__col.is-1-tablet {
      flex: none;
      width: 8.33333%; }
    .l-grid__col.is-offset-1-tablet {
      margin-left: 8.33333%; }
    .l-grid__col.is-2-tablet {
      flex: none;
      width: 16.66667%; }
    .l-grid__col.is-offset-2-tablet {
      margin-left: 16.66667%; }
    .l-grid__col.is-3-tablet {
      flex: none;
      width: 25%; }
    .l-grid__col.is-offset-3-tablet {
      margin-left: 25%; }
    .l-grid__col.is-4-tablet {
      flex: none;
      width: 33.33333%; }
    .l-grid__col.is-offset-4-tablet {
      margin-left: 33.33333%; }
    .l-grid__col.is-5-tablet {
      flex: none;
      width: 41.66667%; }
    .l-grid__col.is-offset-5-tablet {
      margin-left: 41.66667%; }
    .l-grid__col.is-6-tablet {
      flex: none;
      width: 50%; }
    .l-grid__col.is-offset-6-tablet {
      margin-left: 50%; }
    .l-grid__col.is-7-tablet {
      flex: none;
      width: 58.33333%; }
    .l-grid__col.is-offset-7-tablet {
      margin-left: 58.33333%; }
    .l-grid__col.is-8-tablet {
      flex: none;
      width: 66.66667%; }
    .l-grid__col.is-offset-8-tablet {
      margin-left: 66.66667%; }
    .l-grid__col.is-9-tablet {
      flex: none;
      width: 75%; }
    .l-grid__col.is-offset-9-tablet {
      margin-left: 75%; }
    .l-grid__col.is-10-tablet {
      flex: none;
      width: 83.33333%; }
    .l-grid__col.is-offset-10-tablet {
      margin-left: 83.33333%; }
    .l-grid__col.is-11-tablet {
      flex: none;
      width: 91.66667%; }
    .l-grid__col.is-offset-11-tablet {
      margin-left: 91.66667%; }
    .l-grid__col.is-12-tablet {
      flex: none;
      width: 100%; }
    .l-grid__col.is-offset-12-tablet {
      margin-left: 100%; } }
  @media screen and (min-width: 1040px) {
    .l-grid__col.is-1-desktop {
      flex: none;
      width: 8.33333%; }
    .l-grid__col.is-offset-1-desktop {
      margin-left: 8.33333%; }
    .l-grid__col.is-2-desktop {
      flex: none;
      width: 16.66667%; }
    .l-grid__col.is-offset-2-desktop {
      margin-left: 16.66667%; }
    .l-grid__col.is-3-desktop {
      flex: none;
      width: 25%; }
    .l-grid__col.is-offset-3-desktop {
      margin-left: 25%; }
    .l-grid__col.is-4-desktop {
      flex: none;
      width: 33.33333%; }
    .l-grid__col.is-offset-4-desktop {
      margin-left: 33.33333%; }
    .l-grid__col.is-5-desktop {
      flex: none;
      width: 41.66667%; }
    .l-grid__col.is-offset-5-desktop {
      margin-left: 41.66667%; }
    .l-grid__col.is-6-desktop {
      flex: none;
      width: 50%; }
    .l-grid__col.is-offset-6-desktop {
      margin-left: 50%; }
    .l-grid__col.is-7-desktop {
      flex: none;
      width: 58.33333%; }
    .l-grid__col.is-offset-7-desktop {
      margin-left: 58.33333%; }
    .l-grid__col.is-8-desktop {
      flex: none;
      width: 66.66667%; }
    .l-grid__col.is-offset-8-desktop {
      margin-left: 66.66667%; }
    .l-grid__col.is-9-desktop {
      flex: none;
      width: 75%; }
    .l-grid__col.is-offset-9-desktop {
      margin-left: 75%; }
    .l-grid__col.is-10-desktop {
      flex: none;
      width: 83.33333%; }
    .l-grid__col.is-offset-10-desktop {
      margin-left: 83.33333%; }
    .l-grid__col.is-11-desktop {
      flex: none;
      width: 91.66667%; }
    .l-grid__col.is-offset-11-desktop {
      margin-left: 91.66667%; }
    .l-grid__col.is-12-desktop {
      flex: none;
      width: 100%; }
    .l-grid__col.is-offset-12-desktop {
      margin-left: 100%; } }
  @media screen and (min-width: 1200px) {
    .l-grid__col.is-1-full {
      flex: none;
      width: 8.33333%; }
    .l-grid__col.is-offset-1-full {
      margin-left: 8.33333%; }
    .l-grid__col.is-2-full {
      flex: none;
      width: 16.66667%; }
    .l-grid__col.is-offset-2-full {
      margin-left: 16.66667%; }
    .l-grid__col.is-3-full {
      flex: none;
      width: 25%; }
    .l-grid__col.is-offset-3-full {
      margin-left: 25%; }
    .l-grid__col.is-4-full {
      flex: none;
      width: 33.33333%; }
    .l-grid__col.is-offset-4-full {
      margin-left: 33.33333%; }
    .l-grid__col.is-5-full {
      flex: none;
      width: 41.66667%; }
    .l-grid__col.is-offset-5-full {
      margin-left: 41.66667%; }
    .l-grid__col.is-6-full {
      flex: none;
      width: 50%; }
    .l-grid__col.is-offset-6-full {
      margin-left: 50%; }
    .l-grid__col.is-7-full {
      flex: none;
      width: 58.33333%; }
    .l-grid__col.is-offset-7-full {
      margin-left: 58.33333%; }
    .l-grid__col.is-8-full {
      flex: none;
      width: 66.66667%; }
    .l-grid__col.is-offset-8-full {
      margin-left: 66.66667%; }
    .l-grid__col.is-9-full {
      flex: none;
      width: 75%; }
    .l-grid__col.is-offset-9-full {
      margin-left: 75%; }
    .l-grid__col.is-10-full {
      flex: none;
      width: 83.33333%; }
    .l-grid__col.is-offset-10-full {
      margin-left: 83.33333%; }
    .l-grid__col.is-11-full {
      flex: none;
      width: 91.66667%; }
    .l-grid__col.is-offset-11-full {
      margin-left: 91.66667%; }
    .l-grid__col.is-12-full {
      flex: none;
      width: 100%; }
    .l-grid__col.is-offset-12-full {
      margin-left: 100%; } }
  @media screen and (min-width: 1400px) {
    .l-grid__col.is-1-hd {
      flex: none;
      width: 8.33333%; }
    .l-grid__col.is-offset-1-hd {
      margin-left: 8.33333%; }
    .l-grid__col.is-2-hd {
      flex: none;
      width: 16.66667%; }
    .l-grid__col.is-offset-2-hd {
      margin-left: 16.66667%; }
    .l-grid__col.is-3-hd {
      flex: none;
      width: 25%; }
    .l-grid__col.is-offset-3-hd {
      margin-left: 25%; }
    .l-grid__col.is-4-hd {
      flex: none;
      width: 33.33333%; }
    .l-grid__col.is-offset-4-hd {
      margin-left: 33.33333%; }
    .l-grid__col.is-5-hd {
      flex: none;
      width: 41.66667%; }
    .l-grid__col.is-offset-5-hd {
      margin-left: 41.66667%; }
    .l-grid__col.is-6-hd {
      flex: none;
      width: 50%; }
    .l-grid__col.is-offset-6-hd {
      margin-left: 50%; }
    .l-grid__col.is-7-hd {
      flex: none;
      width: 58.33333%; }
    .l-grid__col.is-offset-7-hd {
      margin-left: 58.33333%; }
    .l-grid__col.is-8-hd {
      flex: none;
      width: 66.66667%; }
    .l-grid__col.is-offset-8-hd {
      margin-left: 66.66667%; }
    .l-grid__col.is-9-hd {
      flex: none;
      width: 75%; }
    .l-grid__col.is-offset-9-hd {
      margin-left: 75%; }
    .l-grid__col.is-10-hd {
      flex: none;
      width: 83.33333%; }
    .l-grid__col.is-offset-10-hd {
      margin-left: 83.33333%; }
    .l-grid__col.is-11-hd {
      flex: none;
      width: 91.66667%; }
    .l-grid__col.is-offset-11-hd {
      margin-left: 91.66667%; }
    .l-grid__col.is-12-hd {
      flex: none;
      width: 100%; }
    .l-grid__col.is-offset-12-hd {
      margin-left: 100%; } }

.l-grid.is-variable {
  --columnGap: 0.75rem;
  margin-left: calc(-1 * var(--columnGap));
  margin-right: calc(-1 * var(--columnGap)); }
  .l-grid.is-variable .l-grid__col {
    padding-left: var(--columnGap);
    padding-right: var(--columnGap); }
  .l-grid.is-variable.is-0 {
    --columnGap: 0rem; }
  @media screen and (max-width: 767px) {
    .l-grid.is-variable.is-0-mobile {
      --columnGap: 0rem; } }
  @media screen and (min-width: 768px), print {
    .l-grid.is-variable.is-0-tablet {
      --columnGap: 0rem; } }
  @media screen and (min-width: 768px) and (max-width: 1039px) {
    .l-grid.is-variable.is-0-tablet-only {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1040px) {
    .l-grid.is-variable.is-0-desktop {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1040px) and (max-width: 1199px) {
    .l-grid.is-variable.is-0-desktop-only {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1200px) {
    .l-grid.is-variable.is-0-full {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .l-grid.is-variable.is-0-full-only {
      --columnGap: 0rem; } }
  @media screen and (min-width: 1400px) {
    .l-grid.is-variable.is-0-hd {
      --columnGap: 0rem; } }
  .l-grid.is-variable.is-1 {
    --columnGap: 0.25rem; }
  @media screen and (max-width: 767px) {
    .l-grid.is-variable.is-1-mobile {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 768px), print {
    .l-grid.is-variable.is-1-tablet {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 768px) and (max-width: 1039px) {
    .l-grid.is-variable.is-1-tablet-only {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1040px) {
    .l-grid.is-variable.is-1-desktop {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1040px) and (max-width: 1199px) {
    .l-grid.is-variable.is-1-desktop-only {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1200px) {
    .l-grid.is-variable.is-1-full {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .l-grid.is-variable.is-1-full-only {
      --columnGap: 0.25rem; } }
  @media screen and (min-width: 1400px) {
    .l-grid.is-variable.is-1-hd {
      --columnGap: 0.25rem; } }
  .l-grid.is-variable.is-2 {
    --columnGap: 0.5rem; }
  @media screen and (max-width: 767px) {
    .l-grid.is-variable.is-2-mobile {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 768px), print {
    .l-grid.is-variable.is-2-tablet {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 768px) and (max-width: 1039px) {
    .l-grid.is-variable.is-2-tablet-only {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1040px) {
    .l-grid.is-variable.is-2-desktop {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1040px) and (max-width: 1199px) {
    .l-grid.is-variable.is-2-desktop-only {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1200px) {
    .l-grid.is-variable.is-2-full {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .l-grid.is-variable.is-2-full-only {
      --columnGap: 0.5rem; } }
  @media screen and (min-width: 1400px) {
    .l-grid.is-variable.is-2-hd {
      --columnGap: 0.5rem; } }
  .l-grid.is-variable.is-3 {
    --columnGap: 0.75rem; }
  @media screen and (max-width: 767px) {
    .l-grid.is-variable.is-3-mobile {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 768px), print {
    .l-grid.is-variable.is-3-tablet {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 768px) and (max-width: 1039px) {
    .l-grid.is-variable.is-3-tablet-only {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1040px) {
    .l-grid.is-variable.is-3-desktop {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1040px) and (max-width: 1199px) {
    .l-grid.is-variable.is-3-desktop-only {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1200px) {
    .l-grid.is-variable.is-3-full {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .l-grid.is-variable.is-3-full-only {
      --columnGap: 0.75rem; } }
  @media screen and (min-width: 1400px) {
    .l-grid.is-variable.is-3-hd {
      --columnGap: 0.75rem; } }
  .l-grid.is-variable.is-4 {
    --columnGap: 1rem; }
  @media screen and (max-width: 767px) {
    .l-grid.is-variable.is-4-mobile {
      --columnGap: 1rem; } }
  @media screen and (min-width: 768px), print {
    .l-grid.is-variable.is-4-tablet {
      --columnGap: 1rem; } }
  @media screen and (min-width: 768px) and (max-width: 1039px) {
    .l-grid.is-variable.is-4-tablet-only {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1040px) {
    .l-grid.is-variable.is-4-desktop {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1040px) and (max-width: 1199px) {
    .l-grid.is-variable.is-4-desktop-only {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1200px) {
    .l-grid.is-variable.is-4-full {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .l-grid.is-variable.is-4-full-only {
      --columnGap: 1rem; } }
  @media screen and (min-width: 1400px) {
    .l-grid.is-variable.is-4-hd {
      --columnGap: 1rem; } }
  .l-grid.is-variable.is-5 {
    --columnGap: 1.25rem; }
  @media screen and (max-width: 767px) {
    .l-grid.is-variable.is-5-mobile {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 768px), print {
    .l-grid.is-variable.is-5-tablet {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 768px) and (max-width: 1039px) {
    .l-grid.is-variable.is-5-tablet-only {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1040px) {
    .l-grid.is-variable.is-5-desktop {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1040px) and (max-width: 1199px) {
    .l-grid.is-variable.is-5-desktop-only {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1200px) {
    .l-grid.is-variable.is-5-full {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .l-grid.is-variable.is-5-full-only {
      --columnGap: 1.25rem; } }
  @media screen and (min-width: 1400px) {
    .l-grid.is-variable.is-5-hd {
      --columnGap: 1.25rem; } }
  .l-grid.is-variable.is-6 {
    --columnGap: 1.5rem; }
  @media screen and (max-width: 767px) {
    .l-grid.is-variable.is-6-mobile {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 768px), print {
    .l-grid.is-variable.is-6-tablet {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 768px) and (max-width: 1039px) {
    .l-grid.is-variable.is-6-tablet-only {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1040px) {
    .l-grid.is-variable.is-6-desktop {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1040px) and (max-width: 1199px) {
    .l-grid.is-variable.is-6-desktop-only {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1200px) {
    .l-grid.is-variable.is-6-full {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .l-grid.is-variable.is-6-full-only {
      --columnGap: 1.5rem; } }
  @media screen and (min-width: 1400px) {
    .l-grid.is-variable.is-6-hd {
      --columnGap: 1.5rem; } }
  .l-grid.is-variable.is-7 {
    --columnGap: 1.75rem; }
  @media screen and (max-width: 767px) {
    .l-grid.is-variable.is-7-mobile {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 768px), print {
    .l-grid.is-variable.is-7-tablet {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 768px) and (max-width: 1039px) {
    .l-grid.is-variable.is-7-tablet-only {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1040px) {
    .l-grid.is-variable.is-7-desktop {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1040px) and (max-width: 1199px) {
    .l-grid.is-variable.is-7-desktop-only {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1200px) {
    .l-grid.is-variable.is-7-full {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .l-grid.is-variable.is-7-full-only {
      --columnGap: 1.75rem; } }
  @media screen and (min-width: 1400px) {
    .l-grid.is-variable.is-7-hd {
      --columnGap: 1.75rem; } }
  .l-grid.is-variable.is-8 {
    --columnGap: 2rem; }
  @media screen and (max-width: 767px) {
    .l-grid.is-variable.is-8-mobile {
      --columnGap: 2rem; } }
  @media screen and (min-width: 768px), print {
    .l-grid.is-variable.is-8-tablet {
      --columnGap: 2rem; } }
  @media screen and (min-width: 768px) and (max-width: 1039px) {
    .l-grid.is-variable.is-8-tablet-only {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1040px) {
    .l-grid.is-variable.is-8-desktop {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1040px) and (max-width: 1199px) {
    .l-grid.is-variable.is-8-desktop-only {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1200px) {
    .l-grid.is-variable.is-8-full {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .l-grid.is-variable.is-8-full-only {
      --columnGap: 2rem; } }
  @media screen and (min-width: 1400px) {
    .l-grid.is-variable.is-8-hd {
      --columnGap: 2rem; } }

body {
  background-color: #282C34;
  color: #EFF1EF;
  font-family: "Karla", "Helvetica Neue", "Arial", sans-serif;
  overflow-x: hidden; }

.c-logo {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 2rem; }
  .c-logo img {
    max-width: 400px;
    width: 100%;
    height: auto; }

.c-content-block {
  width: 100%; }

#formresult {
  margin-bottom: 1rem; }

.c-logo:after {
  content: '';
  max-width: 400px;
  width: 90%;
  height: 1px;
  background-color: #535B69;
  position: absolute;
  bottom: 0; }

.l-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh; }

.c-form {
  display: flex;
  justify-content: center; }
  .c-form form {
    width: 100%;
    max-width: 400px; }

.c-form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.5rem; }

.c-form-group__control {
  width: 100%;
  padding: .5rem;
  background-color: #535B69;
  color: #EFF1EF;
  border: none;
  font-size: 14px;
  transition: background-color .3s ease; }

.c-form-group__control:focus {
  outline: none;
  background-color: #3b4352; }

textarea {
  resize: vertical;
  height: 120px; }

.c-form-group__control::placeholder {
  color: #EFF1EF; }

.c-form-group__label {
  margin-bottom: .5rem;
  font-size: 13px;
  color: #aab1bd;
  text-transform: uppercase; }

.c-button-group {
  display: flex;
  justify-content: space-between; }

.c-btn {
  border: none;
  border-radius: 0;
  color: #000;
  text-transform: uppercase;
  width: 49%;
  padding: .5rem 1rem; }

.c-btn:focus {
  outline: none; }

.c-content-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem; }

.c-content-block * {
  width: 400px;
  max-width: 100%; }

.c-btn {
  background-color: #535B69;
  color: #EFF1EF; }

.c-btn--info {
  background-color: transparent;
  border: 2px solid #535B69;
  transition: all .3s ease; }

.c-btn--info:disabled {
  background-color: #b9b9b9;
  border: 2px solid #c1c1c1;
  color: #cacaca;
  cursor: wait; }

.c-btn--info:hover {
  border-color: #677284;
  box-shadow: 0px 0px 15px rgba(115, 126, 146, 0.29); }

.c-btn--danger {
  transition: all 0.3s ease; }

.c-btn--danger:hover {
  background-color: #3b4352; }
