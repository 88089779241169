// VP Framework 

// Layouts
@mixin clearfix {
  &::after{
    clear: both;
    content: ' ';
    display: table;
  }
}

// Breakpoints
@mixin from($device) {
  @media screen and (min-width: $device) {
    @content;
  }
}

@mixin until($device) {
  @media screen and ( max-width: $device - 1px ) {
    @content;
  }
}

@mixin mobile {
  @if $bp-mobile-enabled {
    @media screen and (max-width: $bp-tablet - 1px) {
        @content;
    }
  }
}

@mixin tablet {
  @if $bp-tablet-enabled {
    @media screen and (min-width: $bp-tablet), print {
        @content;
    }
  }
}

@mixin tablet-only {
  @if $bp-tablet-enabled and $bp-desktop-enabled {
    @media screen and (min-width: $bp-tablet) and (max-width: $bp-desktop - 1px) {
      @content;
    }
  }
}

@mixin desktop {
  @if $bp-desktop-enabled {
    @media screen and (min-width: $bp-desktop) {
      @content;
    }
  }
}

@mixin desktop-only {
  @if $bp-desktop-enabled and $bp-full-enabled {
    @media screen and (min-width: $bp-desktop) and (max-width: $bp-full - 1px) {
      @content;
    }
  }
}

@mixin until-full {
  @if $bp-full-enabled {
    @media screen and (max-width: $bp-full - 1px) {
      @content;
    }
  }
}

@mixin full {
  @if $bp-full-enabled {
    @media screen and (min-width: $bp-full) {
      @content;
    }
  }
}

@mixin full-only {
  @if $bp-full-enabled and $bp-hd-enabled {
    @media screen and (min-width: $bp-full) and (max-width: $bp-hd - 1px) {
      @content;
    }
  }
}

@mixin until-hd {
  @if $bp-hd-enabled {
    @media screen and (max-width: $bp-hd - 1px) {
      @content;
    }
  }
}

@mixin hd {
  @if $bp-hd-enabled {
    @media screen and (min-width: $bp-hd) {
      @content;
    }
  }
}