$brand-standard-issue: #282C34;
$brand-old-enamel: #EFF1EF;
$brand-blastoff: #D65739;
$brand-slate: #535B69;
$brand-toffee-tint: #C4A58E;
$brand-fresh-butter: #eee6c8;
$brand-sugar: #fff;
$brand-silver: #F2F2F4;

$base: 14px !default;
$base-line: 1.25 !default;

$bp-mobile: 520px;
$bp-tablet: 768px;
$bp-desktop: 1040px;
$bp-full: 1200px;
$bp-hd: 1400px;


$base-line: 1.953;

$ff-body: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$ff-head: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$ff-subhead: 'Montserrat', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$ff-script: 'Learning Curve', cursive;


$anim-duration: 0.3s; 

.s-style-guide {
  position: absolute;
  font-family: $ff-body;
  font-weight: bold;
  font-size: 14px; 
  background-color: #eaeaea;
  margin-bottom: 4rem;
  padding: .1rem .6rem;
  display: inline-block;
  text-transform:uppercase;
  border-radius: .25rem;
}

.s-section {
  margin-bottom: 32px;
}