// This file is namespaced
// o-   object
// c-   component
// u-   utility
// js-  binding for javascript interactivety
// qa-  binding for quality assurance testing


// User Variables
// We include user vars first to allow the override of framework
// variables where necessary;

@import '_user-var.scss';

// Framework

@import 'framework/_include.scss';

// Partials

body {
  background-color: $brand-standard-issue;
  color: $brand-old-enamel;
  font-family: "Karla", "Helvetica Neue", "Arial", sans-serif;
  overflow-x: hidden;
}

.c-logo {
  display: flex;
  justify-content: center;
  position: relative;
  img {
    max-width: 400px;
    width: 100%;
    height: auto;
  }
  margin-bottom: 2rem;
}

.c-content-block {
  width: 100%;
  
}

#formresult {
  margin-bottom: 1rem;
}

.c-logo:after {
  content: '';
  max-width: 400px;
  width: 90%;
  height: 1px;
  background-color: $brand-slate;
  position: absolute;
  bottom: 0;
}

.l-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
}

.c-form {
  display: flex;
  justify-content: center;
  form {
    width: 100%;
    max-width: 400px;
  }
}

.c-form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.c-form-group__control {
  width: 100%;
  padding: .5rem;
  background-color: $brand-slate;
  color: $brand-old-enamel;
  border: none;
  font-size: 14px;
  transition: background-color .3s ease;
}

.c-form-group__control {
  &:focus {
    outline: none;
    background-color: #3b4352
  }
}

textarea {
  resize: vertical;
  height: 120px;
}

.c-form-group__control::placeholder {
  color: $brand-old-enamel;
}

.c-form-group__label {
  margin-bottom: .5rem;
  font-size: 13px;
  color: #aab1bd;
  text-transform: uppercase;
}

.c-form-group, .c-form-group__control {
  //display: block;
  // flex-direction: column;
}

.c-button-group {
  display: flex;
  justify-content: space-between;
}

.c-btn {
  border: none;
  border-radius: 0;
  color: #000;
  text-transform: uppercase;
  width: 49%;
  padding: .5rem 1rem;
}

.c-btn:focus {
  outline: none;
}

.c-content-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.c-content-block * {
  width: 400px;
  max-width: 100%;
}

.c-btn {
  background-color: $brand-slate;
  color: $brand-old-enamel;
}

.c-btn--info {
  background-color: transparent;
  border: 2px solid $brand-slate;
  transition: all .3s ease;
}

.c-btn--info:disabled {
  background-color: #b9b9b9;
  border: 2px solid #c1c1c1;
  color: #cacaca;
  cursor: wait;
}

.c-btn--info:hover {
  border-color: #677284;
  box-shadow: 0px 0px 15px rgba(115, 126, 146, .29);
}

.c-btn--danger {
  transition: all 0.3s ease;
}

.c-btn--danger:hover{
  background-color: #3b4352;
}

// @import 'partials/_fonts.scss';
// @import 'partials/_type.scss';
// @import 'partials/_hamburger.scss';
// @import 'partials/_sprite.scss';
// @import 'partials/_optin.scss';
// @import 'partials/_modal.scss';
// @import 'partials/_forms.scss';
// @import 'partials/_wpmc.scss';
// @import 'partials/_select2.scss';
// @import 'partials/_images.scss';
// @import 'partials/_links.scss';
// @import 'partials/_tables.scss';
// @import 'partials/_forms.scss';