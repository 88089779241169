// Base 

$base: 16px !default;
$base-line: 1.25 !default;

@function calcRem($size) {
  $remSize: $size / $base;
  @return $remSize + 0rem;
}


// Breakpoints

$bp-mobile: 580px;
$bp-tablet: 768px;
$bp-desktop: 1040px;
$bp-full: 1200px;
$bp-hd: 1400px;

$bp-mobile-rem: calcRem($bp-mobile);
$bp-tablet-rem: calcRem($bp-tablet);
$bp-desktop-rem: calcRem($bp-desktop);
$bp-full-rem: calcRem($bp-full);
$bp-hd-rem: calcRem($bp-hd);

$bp-mobile-enabled: true !default;
$bp-tablet-enabled: true !default;
$bp-desktop-enabled: true !default;
$bp-full-enabled: true !default;
$bp-hd-enabled: true !default;


// Grid

$column-gap: $base-line + 0rem !default;
$variable-columns: true !default;


// Layout 

$gap: $column-gap * 1 !default;


