.l-container {
  margin: 0 auto;
  position: relative;
  
  @include until( $bp-tablet ) {
    margin-left: $gap;
    margin-right: $gap;
  }

  @include tablet {
    max-width: $bp-tablet-rem - 2 * $gap;
    width: $bp-tablet-rem - 2 * $gap;
  }

  @include desktop {
    max-width: $bp-desktop-rem - 2 * $gap;
    width: $bp-desktop-rem - 2 * $gap;
    
    &.is-fluid {
      margin-left: $gap;
      margin-right: $gap;
      max-width: none;
      width: auto;
    }
  }

  @include full {
    max-width: $bp-full-rem - 2 * $gap;
    width: $bp-full-rem - 2 * $gap;
  }
  
  @include hd {
    max-width: $bp-hd-rem - 2 * $gap;
    width: $bp-hd-rem - 2 * $gap;
  }

  @include until-full {
    &.is-full {
      max-width: $bp-full-rem - 2 * $gap;
      width: auto;
    }
  }

  @include until-hd {
    &.is-hd {
      max-width: $bp-hd-rem - 2 * $gap;
      width: auto;
    }
  }

}

.l-container.is-unset {
  position: unset;
}

.o-section {
    margin: 0 0 2 * $gap 0;
}